import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface GenesisNFTState {
  remainingSupply: number[];
}

const initialState: GenesisNFTState = {
  remainingSupply: [0, 0, 0, 0, 0, 0],
};

const genesisNFTSlice = createSlice({
  name: 'genesisNFT',
  initialState,
  reducers: {
    setRemainingSupply: (state, action: PayloadAction<number[]>) => {
      state.remainingSupply = action.payload;
    },
    updateSupplyByTier: (
      state,
      action: PayloadAction<{ tier: number; value: number }>
    ) => {
      const { tier, value } = action.payload;
      state.remainingSupply[tier - 1] = value;
    },
    decrementSupplyByTier: (
      state,
      action: PayloadAction<{ tier: number; amount: number }>
    ) => {
      const { tier, amount } = action.payload;
      state.remainingSupply[tier - 1] -= amount;
    },
  },
});

export const { setRemainingSupply, updateSupplyByTier, decrementSupplyByTier } =
  genesisNFTSlice.actions;
export default genesisNFTSlice.reducer;
