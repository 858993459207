import { useReadContract } from 'wagmi';
import ABI from '@/0xer_abi.json';
import { Oxer_CONTRACT_ADDRESS } from '../utils/constants';

const useTotalSupply = () => {
  const { data, isError, isLoading } = useReadContract({
    address: Oxer_CONTRACT_ADDRESS,
    abi: ABI,
    functionName: 'totalSupply',
  });

  return {
    totalSupply: data ? Number(data) : 0,
    isError,
    isLoading,
  };
};

export default useTotalSupply;
