import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface RankingData {
  twinMatrix: number;
  AlltwinMatrix: number;
  points: number;
  invitedTasks: number;
  completedTasks: number;
  completionRate?: number;
}

const initialState: RankingData = {
  twinMatrix: 0,
  AlltwinMatrix: 256,
  points: 0,
  invitedTasks: 0,
  completedTasks: 0,
  completionRate: 0,
};

const rankingPanelSlice = createSlice({
  name: 'rankingPanel',
  initialState,
  reducers: {
    incrementTwinMatrix: (state) => {
      state.twinMatrix += 1;
    },
    decrementTwinMatrix: (state) => {
      state.twinMatrix -= 1;
    },
    incrementPoints: (state) => {
      state.points += 1;
    },
    decrementPoints: (state) => {
      state.points -= 1;
    },
    setPoint: (state, action: PayloadAction<number>) => {
      state.points = action.payload;
    },
    incrementInvitedTasks: (state) => {
      state.invitedTasks += 1;
    },
    setInvitedTasks: (state, action: PayloadAction<number>) => {
      state.invitedTasks = action.payload;
    },
    decrementInvitedTasks: (state) => {
      state.invitedTasks -= 1;
    },
    incrementCompletedTasks: (state) => {
      state.completedTasks += 1;
    },
    decrementCompletedTasks: (state) => {
      state.completedTasks -= 1;
    },
    calculateCompletionRate: (state) => {
      state.completionRate =
        state.invitedTasks > 0
          ? Math.floor((state.completedTasks / state.invitedTasks) * 100)
          : 0;
    },
    setRankingData: (state, action: PayloadAction<RankingData>) => {
      state.twinMatrix = action.payload.twinMatrix;
      state.AlltwinMatrix = action.payload.AlltwinMatrix;
      state.points = action.payload.points;
      state.invitedTasks = action.payload.invitedTasks;
      state.completedTasks = action.payload.completedTasks;
      state.completionRate =
        state.invitedTasks > 0
          ? Math.floor((state.completedTasks / state.invitedTasks) * 100)
          : 0;
    },
  },
});

export const {
  incrementTwinMatrix,
  decrementTwinMatrix,
  incrementPoints,
  decrementPoints,
  setPoint,
  incrementInvitedTasks,
  decrementInvitedTasks,
  setInvitedTasks,
  incrementCompletedTasks,
  decrementCompletedTasks,
  calculateCompletionRate,
  setRankingData,
} = rankingPanelSlice.actions;

export default rankingPanelSlice.reducer;
