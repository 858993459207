import { useAppDispatch, useAppSelector } from '../state/hooks'
import {
  decrement,
  increment,
} from "../state/counter/counterSlice";
import { Box, Button } from '@chakra-ui/react';

const Counter = () => {
  const count = useAppSelector((state) => state.counter.value);
  const dispatch = useAppDispatch();

  return (
    <Box color={'block'}>
      <div>
        <h1>{count}</h1>
        <div>
          <Button onClick={() => dispatch(increment())}>Increment</Button>
          <Button onClick={() => dispatch(decrement())}>Decrement</Button>
        </div>
      </div>
    </Box>
  );
};

export default Counter;