import Counter from '@/components/Counter'
import { Box } from '@chakra-ui/react'
import React from 'react'

const ReduxTest = () => {
  return (
    <Box color={'black'}>
      <h1>ReduxTest</h1>
      <Counter />
    </Box>
  )
}

export default ReduxTest