import { useCallback, useEffect, useState, useContext } from 'react';
import { useWriteContract, useWaitForTransactionReceipt } from 'wagmi';
import USDT_ABI from '@/USDT_abi.json';
import SCORE_ABI from '@/Twin3ScoreExchange_abi.json';
import {
  USDT_CONTRACT_ADDRESS,
  Twin3ScoreExchange_CONTRACT_ADDRESS,
} from '@/utils/constants';
import { useToast } from '@chakra-ui/react';
import { parseEther } from 'viem';
import AccountContext from '@/context/account';
import useReadScore from '@/libs/useReadScore';

const useUpdateSBT = (
  accountInfo: any,
  id: number | undefined,
  amount: number | 1
) => {
  const { data: hash, writeContract } = useWriteContract();
  const { isSuccess: isConfirmed } = useWaitForTransactionReceipt({
    hash,
  });
  // usestate set signature

  const [signature, setSignature] = useState<string>();
  const { account } = useContext(AccountContext);
  const { refetch } = useReadScore(account || '');

  const toast = useToast();

  const submit = useCallback(
    async (signature: `0x${string}`) => {
      try {
        setSignature(signature);
        writeContract({
          abi: USDT_ABI,
          address: USDT_CONTRACT_ADDRESS,
          functionName: 'approve',
          args: [
            Twin3ScoreExchange_CONTRACT_ADDRESS,
            parseEther(amount.toString()),
          ],
        });
        console.log('USDT', hash);
      } catch (error) {
        console.error('Error executing transactions:', error);
        toast({
          title: 'Contract submission failed!',
          status: 'error',
        });
      }
    },
    [writeContract, amount, toast, hash]
  );

  useEffect(() => {
    if (isConfirmed) {
      writeContract({
        abi: SCORE_ABI,
        address: Twin3ScoreExchange_CONTRACT_ADDRESS,
        functionName: 'updateSBT',
        args: [
          parseEther(amount.toString()),
          id,
          `0x${accountInfo.data}`,
          signature,
        ],
      });
    }
  }, [
    hash,
    isConfirmed,
    toast,
    accountInfo,
    id,
    amount,
    signature,
    writeContract,
  ]);

  useEffect(() => {
    if (hash && isConfirmed) {
      toast({
        title: 'Transaction Success',
        status: 'success',
      });
      refetch();
    }
  }, [hash, toast, isConfirmed, refetch]);

  return { submit };
};

export default useUpdateSBT;
