import { Box, Button, Flex, Image, Link, Text } from "@chakra-ui/react";
import arbitrumLogo from "@/assets/images/arbitrum.png";
import ChapterAccordion from "@/components/ChapterAccordion";
import { RouteComponentProps } from "react-router-dom";
import { useEffect } from "react";
import useTotalSupply from "@/libs/useTotalSupply";

const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;

const Main = ({ history }: { history: RouteComponentProps["history"] }) => {
  const { totalSupply: totalMint, isError } = useTotalSupply();

  useEffect(() => {
    if (isError) {
      console.error("Failed to fetch total supply");
    }
  }, [isError]);

  return (
    <Box bg="black">
      {/* Jumbotron begin */}
      <Flex bg="black" mt="94px" px={3} p="lg">
        <Flex m="auto" direction="column" align="center" p={5}>
          <Text
            fontSize="3xl"
            pt={10}
            fontWeight="bold"
            align="center"
            letterSpacing={2}
          >
            Preserve and empower human wisdom
          </Text>
          <Text
            fontSize="3xl"
            fontWeight="bold"
            align="center"
            letterSpacing={2}
            pb={5}
          >
            in the emerging AI era.
          </Text>
          <Text
            maxW={800}
            align={{ base: "center", md: "center" }}
            fontSize={{ base: "sm", md: "large" }}
            color="gray"
          >
            Twin3 turns your intelligence into digital assets. Your agents work
            and earn in AI ecosystems.
          </Text>
          <Text
            mt={{ base: 2, md: 5 }}
            fontSize="8xl"
            fontFamily="Cursed Timer ULiL"
            letterSpacing={1}
          >
            <Link
              href="https://testnets.opensea.io/collection/0xer-1"
              target="_blank"
              rel="noopener noreferrer"
              _hover={{ textDecor: "none" }}
            >
              {totalMint}
            </Link>
          </Text>
          <Button
            mt={5}
            p={5}
            variant="outlineDark"
            width={300}
            onClick={() => history.push("/tasks/1")}
          >
            Free Mint
          </Button>
          <Text mt={5} align="center" fontSize={{ base: "sm", md: "large" }}>
            Free Gas to Mint Your Twin NFT for Your Personal AI Agents
          </Text>
          <Link
            href={`https://arbiscan.io/address/${CONTRACT_ADDRESS}`}
            rel="noreferrer noopener"
            target="_blank"
          >
            <Image mt={4} width="5rem" src={arbitrumLogo} />
          </Link>
        </Flex>
      </Flex>
      {/* Jumbotron end */}

      {/* Introduction begin */}
      <Box bg="black" p="lg" mb={10}>
        <Text
          fontSize="3xl"
          p={3}
          fontWeight="bold"
          align="center"
          letterSpacing={2}
        >
          Seal and encrypt your traits into Twin Soulbound Token
        </Text>
        <Flex
          gap={10}
          mx="auto"
          maxW={1200}
          direction={{ base: "column", lg: "row" }}
          p={5}
        >
          <Box
            bg="#323332"
            py={10}
            px={8}
            flex={1}
            borderRadius="5px"
            cursor="pointer"
            _hover={{ bg: "#52534F" }}
          >
            <Text fontSize="2xl" fontWeight="bold" textAlign="center">
              Step 1
            </Text>
            <Text fontSize="1xl" fontWeight="bold" mb={3} textAlign="center">
              Soulbound Twin
            </Text>
            <Text fontSize="md" textAlign="left">
              Convert your traits across physical, digital, social and spiritual
              realms into the Twin Matrix — a 256-dimensional soulbound
              representation of your authentic self.
            </Text>
          </Box>
          <Box
            bg="#323332"
            py={10}
            px={8}
            flex={1}
            borderRadius="5px"
            cursor="pointer"
            _hover={{ bg: "#52534F" }}
          >
            <Text fontSize="2xl" fontWeight="bold" textAlign="center">
              Step 2
            </Text>
            <Text fontSize="1xl" fontWeight="bold" mb={3} textAlign="center">
              Soul Injection
            </Text>
            <Text fontSize="md" textAlign="left">
              Inject selected traits into your personal agents. Each agent
              inherits specific abilities, becoming your digital representatives
              in the AI economy.
            </Text>
          </Box>
          <Box
            bg="#323332"
            py={10}
            px={8}
            flex={1}
            borderRadius="5px"
            cursor="pointer"
            _hover={{ bg: "#52534F" }}
          >
            <Text fontSize="2xl" fontWeight="bold" textAlign="center">
              Step 3
            </Text>
            <Text fontSize="1xl" fontWeight="bold" mb={3} textAlign="center">
              Agentic Human
            </Text>
            <Text fontSize="md" textAlign="left">
              Your agents work as human-in-the-loop partners in AI systems,
              automatically finding and completing tasks in agent marketplaces
              to earn continuous value.
            </Text>
          </Box>
        </Flex>
      </Box>
      {/* Introduction end */}
      <hr style={{ border: "0.5px solid gray" }} />
      {/* Tasks begin */}
      <Box bg="black" p="lg">
        <Text
          fontSize="3xl"
          p={5}
          pt={10}
          fontWeight="bold"
          align="center"
          letterSpacing={2}
        >
          Begin with Tasks to Build Your Twin Matrix
        </Text>
        <Flex
          gap={10}
          mx="auto"
          maxW={1200}
          direction={{ base: "column", lg: "row" }}
          p={5}
        >
          <Flex
            border="1px solid white"
            direction="column"
            p={6}
            flex={1}
            borderRadius="5px"
            cursor="pointer"
          >
            <Text fontSize="2xl" fontWeight="bold" mb={2}>
              #001
            </Text>
            <Text fontSize="lg" fontWeight="bold" mb={4}>
              Proof of Humanity
            </Text>
            <Text fontSize="md" mb={8} flex={1}>
              Establish your human authenticity through multi-level
              verifications. Complete our challenges to distinguish yourself
              from AI entities and create your foundational digital identity.
            </Text>
            <Button
              onClick={() => history.push("/tasks/1")}
              p={5}
              width="100%"
              border="1px solid white"
              fontWeight="normal"
              bg="black"
              color="white"
            >
              Start
            </Button>
          </Flex>
          <Flex
            border="1px solid white"
            direction="column"
            p={6}
            flex={1}
            borderRadius="5px"
            cursor="pointer"
          >
            <Text fontSize="2xl" fontWeight="bold" mb={2}>
              #002
            </Text>
            <Text fontSize="lg" fontWeight="bold" mb={4}>
              Human Trust Network
            </Text>
            <Text fontSize="md" mb={8} flex={1}>
              Join a verified network of authentic humans on blockchain. Each
              person you verify strengthens the network's trust layer, building
              a reliable foundation for human-AI collaboration.
            </Text>
            <Button
              onClick={() => history.push("/tasks/2")}
              p={5}
              width="100%"
              border="1px solid white"
              fontWeight="normal"
              bg="black"
              color="white"
            >
              Coming Soon
            </Button>
          </Flex>
          <Flex
            border="1px solid white"
            direction="column"
            p={6}
            flex={1}
            borderRadius="5px"
            cursor="pointer"
          >
            <Text fontSize="2xl" fontWeight="bold" mb={2}>
              #003
            </Text>
            <Text fontSize="lg" fontWeight="bold" mb={4}>
              Web3 Expertise
            </Text>
            <Text fontSize="md" mb={8} flex={1}>
              Validate your Web3 experience through 100 challenges. From Bitcoin
              to DeFi, prove your journey in the decentralized revolution to
              enhance your Twin Matrix capabilities.
            </Text>
            <Button
              onClick={() => history.push("/tasks/3")}
              p={5}
              width="100%"
              border="1px solid white"
              fontWeight="normal"
              bg="black"
              color="white"
            >
              Coming Soon
            </Button>
          </Flex>
        </Flex>
        <Text fontSize="lg" align="center" p={2} maxW={900} mb={3} mx="auto">
          Complete tasks to increase your Twin SBT value. You control when to
          update your achievements on-chain.
        </Text>
      </Box>
      {/* Tasks end */}

      {/* Plan begin */}
      <Box bg="black" p="lg">
        <Text
          fontSize="3xl"
          p={5}
          pt={10}
          fontWeight="bold"
          align="center"
          letterSpacing={2}
        >
          The Plan
        </Text>
        <Flex m="auto" direction="column" align="center">
          <ChapterAccordion
            chapters={[
              {
                chapter: 1,
                title: "Soul Injection Protocol ",
                content: `Activating the agentic human potential" 
                Twin3 launches with the revolutionary Soul Injection protocol, transforming human traits into intelligence assets through soulbound technology. Users create their Twin Matrix SBT, injecting selected traits to form personal agents. This marks the birth of authentic agentic humans in the digital realm.`,
              },
              {
                chapter: 2,
                title: "Third-Party Integration ",
                content: `Personal agents gain compatibility with major workflow platforms (Zapier, Make, n8n). This integration enables agents to participate in diverse AI workflows, demonstrating the value of human-in-the-loop collaboration across existing ecosystems.`,
              },
              {
                chapter: 3,
                title: "Intelligence Asset Framework ",
                content: `"Standardizing human intelligence assets" 
                Twin3 establishes the comprehensive framework for intelligence assets. The 256-dimension Twin Matrix evolves into a standardized protocol for encoding and trading human traits, setting the foundation for the agentic human economy.`,
              },
              {
                chapter: 4,
                title: "Agentic Human Protocol ",
                content: `"Building the talent network infrastructure" 
                Twin3 launches its native marketplace as the Agentic Human Protocol, connecting verified human agents with AI systems. This specialized network ensures authentic human traits are properly valued and efficiently deployed in AI collaboration.`,
              },
              {
                chapter: 5,
                title: "Intelligence-to-Earn Economy",
                content: `"Monetizing human intelligence" 
                The Twin3 ecosystem enables direct value creation from intelligence assets. Personal agents generate sustainable income through task completion, while the Data DAO governance ensures fair value distribution and market integrity.`,
              },
              {
                chapter: 6,
                title: "Cross-Chain Intelligence Network",
                content: `"Creating the unified talent network" 
                Twin3 expands across multiple blockchains, forming an interconnected intelligence asset network. The Agentic Human Protocol becomes the standard interface for human-AI collaboration across all digital platforms.`,
              },
              {
                chapter: 7,
                title: "Universal Human Protocol",
                content: `"Establishing the human-AI collaboration standard" 
                Twin3 achieves recognition as the universal protocol for agentic humans. The ecosystem matures into a comprehensive talent network, enabling seamless collaboration between human intelligence assets and AI systems while preserving human agency.`,
              },
            ]}
          />
        </Flex>
      </Box>
      {/* Plan end */}
    </Box>
  );
};
export default Main;
