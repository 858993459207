import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useGoogleLogin } from "@react-oauth/google";
// @ts-expect-error no v3 types provided
import RecaptchaV2 from "react-google-recaptcha";
import {
  Box,
  Button,
  Center,
  CircularProgress,
  Flex,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import StageCard from "@/components/StageCard";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import {
  getLeaderboard,
  getUser,
  refreshSBT,
  sendSMSMessage,
  verifyOauthResponse,
  verifyRecaptcha,
  verifySMSMessage,
} from "@/api";
import Countdown from "@/components/Countdown";
import CountryCodeMenu from "@/components/CountryCodeMenu";
import useScrollToTop from "@/libs/useScrollToTop";
import AccountContext from "@/context/account";
import LevelIcon from "@/components/LevelIcon";
import recaptchaLogo from "@/assets/images/recaptcha.png";
import smsLogo from "@/assets/images/sms.png";
import faceLogo from "@/assets/images/face.png";
import googleLogo from "@/assets/images/google.png";
import countryCodeList from "@/conuntry-code-list.json";
import ImageLoading from "@/assets/images/loading.gif";
import TaskTemplate from "@/pages/Tasks/TaskTemplate";
import config from "@/pages/Tasks/TaskConfig.json";

enum VerificationStatus {
  IDLE,
  WAITING,
  SUCCESS,
  ERROR,
}

const Verify = () => {
  useScrollToTop();
  const levelRef = useRef<HTMLDivElement>(null);
  const { account, auth } = useContext(AccountContext);
  const [level, setLevel] = useState<number>(0);
  const [phone, setPhone] = useState("");
  const [total, setTotal] = useState<number>();
  const [rank, setRank] = useState<number>();
  const [countryCode, setCountryCode] = useState("+886");
  const [code, setCode] = useState("");
  const lineMode = localStorage.getItem("fromLine") ? true : false;
  const [resendable, setResendable] = useState(true);
  const [showVerifyMessage, setShowVerifyMessage] = useState(false);
  const [dataReay_Task1, setDataReay_Task1] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState(
    VerificationStatus.IDLE
  );
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const task1 = config[0];

  const googleLogin = useGoogleLogin({
    onSuccess: ({ access_token: token }) => {
      if (account)
        verifyOauthResponse("google", account, token)
          .then((redirect_url) => {
            if (redirect_url) {
              window.location.href = redirect_url; // 跳轉到 redirect_url
            } else {
              setVerificationStatus(VerificationStatus.SUCCESS);
            }
          })
          .catch(() => setVerificationStatus(VerificationStatus.ERROR));
    },
    onError: (e) => {
      setVerificationStatus(VerificationStatus.ERROR);
    },
  });


  // 倒數計時相關狀態
  const [countdown, setCountdown] = useState<number>(0);
  const [countdownFinished, setCountdownFinished] = useState<boolean>(true);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (countdown > 0) {
      timeout = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else if (countdown === 0 && !countdownFinished) {
      setCountdownFinished(true);
      setResendable(true);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [countdown, countdownFinished]);

  const startCountdown = (from: number) => {
    setCountdown(from);
    setCountdownFinished(false);
    setResendable(false);
  };

  const sendMessage = useCallback(() => {
    if (account) {
      if (phone === "") {
        toast({
          title: "Type your phone number",
          duration: 5000,
          status: "error",
        });
        return;
      }

      sendSMSMessage(account, countryCode, phone).then(() => {
        startCountdown(60); // 開始倒數計時
        setResendable(false);
        setShowVerifyMessage(true);
      }).catch((error) => {
        toast({
          title: error.message,
          duration: 5000,
          status: "error",
        });
      });
    }
  }, [account, countryCode, phone, toast]);

  const verifyMessage = useCallback(() => {
    if (account) {
      if (code === "") {
        toast({
          title: "Type code",
          duration: 5000,
          status: "error",
        });
        return;
      }

      verifySMSMessage(account, code)
        .then(async () => {
          setVerificationStatus(VerificationStatus.SUCCESS);
          const result = await refreshSBT();
          if (result.success) {
            toast({
              title: "SBT refreshed",
              duration: 5000,
              status: "success",
            });
          }
        })
        .catch(() => {
          setCode("");
          setVerificationStatus(VerificationStatus.ERROR);
        });
    }
  }, [account, code, toast]);

  const finishVerificationProcess = useCallback(
    (success: boolean) => () => {
      setVerificationStatus(VerificationStatus.IDLE);
      onClose();
      if (success) setLevel((n) => n + 1);
    },
    [onClose]
  );

  const onVerifySuccessClick = useCallback(
    () => finishVerificationProcess(true)(),
    [finishVerificationProcess]
  );

  const recaptchaVerify = useCallback(
    async (v2Response: string) => {
      if (!account || !executeRecaptcha) return;
      const v3Response = await executeRecaptcha("yourAction");
      const responses = { v2: v2Response, v3: v3Response };

      const result = await verifyRecaptcha(account, responses);
      if (result.success) {
        // to next level
        setVerificationStatus(VerificationStatus.SUCCESS);
      }
    },
    [account, executeRecaptcha]
  );

  // initialize apple login button
  useEffect(() => {
    if (isOpen && level === 3) {
      const successHandler = async (event: any) => {
        // Handle successful response.
        const { id_token: token } = event?.detail?.authorization || {};
        if (account)
          if (account)
            verifyOauthResponse("google", account, token)
              .then((redirect_url) => {
                if (redirect_url) {
                  window.location.href = redirect_url; // 跳轉到 redirect_url
                } else {
                  setVerificationStatus(VerificationStatus.SUCCESS);
                }
              })
              .catch(() => setVerificationStatus(VerificationStatus.ERROR));
      };
      const errorHandler = () =>
        setVerificationStatus(VerificationStatus.ERROR);
      setTimeout(() => {
        (window as any).AppleID.auth.init({
          clientId: "signing.org.0xer.app",
          scope: "name email",
          redirectURI: `${window.location.origin}/tasks/1`,
          usePopup: true,
        });
        // Listen for authorization success.
        document.addEventListener("AppleIDSignInOnSuccess", successHandler);
        // Listen for authorization failures.
        document.addEventListener("AppleIDSignInOnFailure", errorHandler);
      }, 0);

      return () => {
        document.removeEventListener("AppleIDSignInOnSuccess", successHandler);
        document.removeEventListener("AppleIDSignInOnSuccess", errorHandler);
      };
    }
  }, [account, isOpen, level]);

  useEffect(() => {
    if (account && auth) {
      const timer = setTimeout(() => {
        if (levelRef.current) {
          console.log("mobile");
          levelRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }, 500);

      // Cleanup function to clear the timeout if the component unmounts before the timeout finishes
      return () => clearTimeout(timer);
    }
  }, [account, auth]);

  // fetch verification status
  useEffect(() => {
    if (auth) {
      setDataReay_Task1(false);
      getUser().then(({ verification_results: verificationResults }) => {
        setLevel((verificationResults?.length || 0) + 1);
        setDataReay_Task1(true);
      });
      getLeaderboard(0x00).then(({ user, data }) => {
        setTotal(data?.length || 0);
        setRank(user?.rank);
      });
    } else {
      setDataReay_Task1(true);
    }
  }, [auth, level, setDataReay_Task1]);

  return (
    <>
      <Modal
        isOpen={isOpen || lineMode}
        onClose={onClose}
        isCentered
        size={{ base: "sm", md: "xl" }}
      >
        <ModalOverlay />
        <Image src={ImageLoading} />

        <ModalContent borderWidth={lineMode ? 0 : 2}>
          {lineMode || (
            <ModalHeader>
              <Text fontSize="lg" top={3} position="absolute" width="90%">
                Level {level}
              </Text>
              <ModalCloseButton />
            </ModalHeader>
          )}
          <ModalBody
            p={5}
            borderTop={lineMode ? "none" : "2px solid #52534F"}
            borderBottom={lineMode ? "none" : "2px solid #52534F"}
          >
            {(() => {
              switch (true) {
                case verificationStatus === VerificationStatus.SUCCESS:
                  return (
                    <Center flexDirection="column">
                      <CheckIcon
                        fontSize="120px"
                        color="#67AE3C"
                        mx={20}
                        mt={20}
                        mb={level === 3 ? 20 : 0}
                      />
                      {level === 1 && (
                        <Text p={6}>
                          You look like a human. Go to level 2 and prove your
                          identity in advance.
                        </Text>
                      )}
                      {level === 2 && (
                        <Text p={6}>
                          Success, your Humanity Verification is being prepared
                          for minting.
                        </Text>
                      )}

                      <Button
                        variant="outlineDark"
                        onClick={onVerifySuccessClick}
                      >
                        OK(
                        <Countdown from={3} onFinish={onVerifySuccessClick} />)
                      </Button>
                    </Center>
                  );
                case verificationStatus === VerificationStatus.ERROR:
                  return (
                    <Center flexDirection="column">
                      <CloseIcon fontSize="120px" color="#B00C0C" m={20} />
                      <Button
                        variant="outlineDark"
                        onClick={finishVerificationProcess(false)}
                      >
                        Retry
                      </Button>
                    </Center>
                  );
                case level === 0:
                  return (
                    <Box p={20} textAlign="center">
                      <CircularProgress isIndeterminate />
                    </Box>
                  );
                case level === 1:
                  return (
                    <Box>
                      <Text my={3}></Text>
                      <Text>Click Google ReCAPTCHA V2</Text>
                      <Box my={3}>
                        <RecaptchaV2
                          sitekey="6LcvoXUnAAAAAJKJNH9lfguHB0ljVxQgLOxCChhR"
                          onChange={recaptchaVerify}
                        />
                      </Box>
                    </Box>
                  );
                case level === 2:
                  return (
                    <Box overflow="auto">
                      <Text>
                        Enter your phone number, receive and enter the
                        verification code.
                      </Text>
                      <Box my={5}>
                        <Flex gap={3} my={4} wrap="wrap">
                          <Flex gap={3} width={{ base: "100%", md: "70%" }}>
                            <CountryCodeMenu
                              value={countryCode}
                              onSelect={setCountryCode}
                              list={countryCodeList}
                            />
                            <Input
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                              placeholder="Type your phone number"
                              flex={3}
                              borderWidth={2}
                            />
                          </Flex>
                          <Button
                            onClick={sendMessage}
                            flex={1}
                            maxW={120}
                            isDisabled={!resendable}
                            borderRadius={4}
                            _disabled={{
                              opacity: 0.6,
                            }}
                          >
                            Send{" "}
                            {resendable || (
                              <>
                                (
                                <Countdown
                                  from={60}
                                  onFinish={() => setResendable(true)}
                                />
                                )
                              </>
                            )}
                          </Button>
                        </Flex>
                        <Flex
                          display={showVerifyMessage ? "flex" : "none"}
                          gap={3}
                          wrap="wrap"
                        >
                          <Input
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            width={{ base: "100%", md: "70%" }}
                            placeholder="Type code"
                          />
                          <Button
                            onClick={verifyMessage}
                            borderRadius={4}
                            flex={1}
                            maxW={120}
                          >
                            OK
                          </Button>
                        </Flex>
                      </Box>
                    </Box>
                  );
                case level === 3:
                  return (
                    <Box>
                      <Text mb={4}>
                        Complete the verification using your Apple or Google
                        account.
                      </Text>
                      <VStack my={5} alignItems="center" gap={3}>
                        <Button
                          variant="outline"
                          bg="white"
                          size="lg"
                          width="302px"
                          borderRadius={8}
                          fontFamily="apple-button-font-0"
                          fontSize="20px"
                          border="none"
                          _hover={{
                            bg: "white",
                          }}
                          onClick={() => googleLogin()}
                        >
                          <Image width={4} src={googleLogo} mr={2} />
                          Continue with Google
                        </Button>
                        <Box>
                          <div
                            id="appleid-signin"
                            data-color="white"
                            data-type="continue"
                            data-mode="center-align"
                            className="apple-login-button"
                          />
                        </Box>
                      </VStack>
                    </Box>
                  );
              }
            })()}
          </ModalBody>
        </ModalContent>
      </Modal>
      <TaskTemplate
        taskNumber={task1.taskNumber}
        title={task1.title}
        description1={task1.description1 || ""}
        description2={task1.description2 || ""}
        participants={total ? total.toLocaleString() : "--"}
        metrics={rank ? rank.toLocaleString() : "--"}
        contributor={task1.contributor}
      >
        <Box flex={3} maxW={500} ref={levelRef}>
          <Flex align="center" gap={3}>
            <Text fontSize="xl" fontWeight={500}>
              Your current Identify level
            </Text>
            {!dataReay_Task1 && (
              <Image src={ImageLoading} width={70} height={30} />
            )}
            {dataReay_Task1 && (
              <Flex gap={4}>
                <LevelIcon level={1} completed={level > 1} />
                <LevelIcon level={2} completed={level > 2} />
                <LevelIcon level={3} completed={level > 3} />
              </Flex>
            )}
          </Flex>
          <Text fontSize="sm" mb={4}>
            Complete at least level 2 to get your DNA NFT free minting.
          </Text>

          <VStack alignItems="stretch" gap={10}>
            <StageCard
              icon={recaptchaLogo}
              level={1}
              current={level}
              onStart={onOpen}
            >
              <Text>Click Google ReCAPTCHA V2</Text>
              <Text fontSize="sm" fontWeight={300}>
                (I'm not a Robot)
              </Text>
            </StageCard>
            <StageCard
              icon={smsLogo}
              level={2}
              current={level}
              onStart={onOpen}
            >
              <Text>Mobile SMS Identity</Text>
            </StageCard>
            <StageCard
              icon={faceLogo}
              level={3}
              current={level}
              onStart={onOpen}
            >
              <Text>Apple ID or Android Authentication</Text>
            </StageCard>
          </VStack>
        </Box>
      </TaskTemplate>
    </>
  );
};

export default Verify;
