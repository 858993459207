import { useReadContract } from 'wagmi';
import ABI from '@/Twin3ScoreExchange_abi.json';
import { Twin3ScoreExchange_CONTRACT_ADDRESS } from '../utils/constants';

const useReadScore = (account: string) => {
  const { data, error, isLoading, refetch } = useReadContract({
    address: Twin3ScoreExchange_CONTRACT_ADDRESS,
    abi: ABI,
    functionName: 'score',
    args: [account],
  });

  return { data, error, isLoading, refetch };
};

export default useReadScore;
