export const Oxer_CONTRACT_ADDRESS = process.env
  .REACT_APP_Oxer_CONTRACT_ADDRESS as `0x${string}`;
export const Twin3Genesis_CONTRACT_ADDRESS = process.env
  .REACT_APP_Twin3Genesis_CONTRACT_ADDRESS as `0x${string}`;
export const Twin3ScoreExchange_CONTRACT_ADDRESS = process.env
  .REACT_APP_Twin3ScoreExchange_CONTRACT_ADDRESS as `0x${string}`;
export const USDT_CONTRACT_ADDRESS = process.env
  .REACT_APP_USDT_CONTRACT_ADDRESS as `0x${string}`;

export const RPC = process.env.REACT_APP_RPC || '';

export const CHAIN_ID = parseInt(process.env.REACT_APP_CHAIN_ID || '0', 10);

export const TEST_MARTIX_DATA = {
  web3_test_results: [],
  verification_results: [1, true, true],
  references: [],
  data: 'FF120000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000',
  claim_hash:
    '0xfb89a637a7224c9d086f74c6462d4fa6c4cc8d8c5918db8be53e4fd4f41ad494',
  address: '0x3F81903Cff28c2bf7017646c61A8f60a954b6617',
  token_id: 39,
  updated_at: 1731470998549,
  on_chain_data:
    '00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000',
};
