import { useEffect, useMemo, useContext } from 'react';
import { Box, Flex, Text, Divider } from '@chakra-ui/react';
import { display_type } from '@/type';
import { setRankingData, setPoint, setInvitedTasks, calculateCompletionRate } from '@/state/rankingPanel/RankingPanelSlice';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import useReadScore from '@/libs/useReadScore'
import AccountContext from "@/context/account";
import { formatEther } from 'viem'
import useNFTData from '@/hooks/useNFTData';

interface RankingData {
  twinMatrix: number;
  AlltwinMatrix: number;
  points: number;
  invitedTasks: number;
  completedTasks: number;
  completionRate: number;
}

interface RankingPanelProps {
  displayValues?: display_type[];
}

const RankingPanel = ({ displayValues = [] }: RankingPanelProps) => {
  const dispatch = useAppDispatch();
  const rankingData = useAppSelector((state) => state.rankingPanel);
  const { account, accountInfo } = useContext(AccountContext);
  const { data: rankingPoint = 0 } = useReadScore(account || '');
  const { taskData } = useNFTData();

  const newRankingData = useMemo(() => {
    const initialRankingData: RankingData = {
      twinMatrix: 0,
      AlltwinMatrix: 256,
      points: 0,
      invitedTasks: 0,
      completedTasks: 0,
      completionRate: 0,
    };
    const data = { ...initialRankingData };

    displayValues.forEach((value) => {
      if (value.data !== '00') {
        data.completedTasks += 1;
        data.twinMatrix += 1;
      }
    });

    return data;
  }, [displayValues]);

  useEffect(() => {
    dispatch(setRankingData(newRankingData));
    if (accountInfo) {
      dispatch(setPoint(Number(formatEther(rankingPoint as bigint))));
    }
    if (accountInfo && taskData.length > 0) {
      console.log('taskData', taskData);
      dispatch(setInvitedTasks(taskData.length));
    }
    dispatch(calculateCompletionRate());
  }, [newRankingData, rankingPoint, dispatch, accountInfo, taskData]);

  useEffect(() => {
    console.log('rankingData', rankingData);
    console.log('rankingPoint', formatEther(rankingPoint as bigint));
  }, [rankingData, rankingPoint]);

  return (
    <Flex justifyContent="space-between" p={4} pl={{ base: 28, md: 0 }} direction={{ base: "column", lg: "row" }}>
      <Box >
        <Text color="black" fontSize="sm">Twin Matrix</Text>
        <Flex align="center">
          <Text color="black" fontSize="4xl" fontWeight="bold">{rankingData.twinMatrix ?? 0}</Text>
          <Text color="black" fontSize="md">/{rankingData.AlltwinMatrix ?? 256}</Text>
        </Flex>
      </Box>
      <Box>
        <Text color="black" fontSize="sm">Points</Text>
        <Text color="black" fontSize="4xl" fontWeight="bold">{rankingData.points ?? 0}</Text>
      </Box>
      <Divider orientation="vertical" borderColor="black" height="auto" />
      <Box>
        <Text color="black" fontSize="sm">Invited Tasks</Text>
        <Text color="black" fontSize="4xl" fontWeight="bold">{rankingData.invitedTasks ?? 0}</Text>
      </Box>
      <Box>
        <Text color="black" fontSize="sm">Completed Tasks</Text>
        <Text color="black" fontSize="4xl" fontWeight="bold">{rankingData.completedTasks ?? 0}</Text>
      </Box>
      <Box>
        <Text color="orange.400" fontSize="sm">Completed Rate</Text>
        <Text fontSize="4xl" fontWeight="bold" color="orange.400">{rankingData.completionRate}%</Text>
      </Box>
    </Flex>
  );
};

export default RankingPanel;