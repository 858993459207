import { useContext } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Image,
  Link,
  Text,
} from "@chakra-ui/react";
import AccountContext from "@/context/account";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Fire_png from "@/assets/images/Fire.png";
import Rectangle_png from "@/assets/images/Rectangle.png";
import { Link as RouterLink } from "react-router-dom";

interface TaskNavigatorProps extends RouteComponentProps {
  leftPram?: string | number;
  topParam?: string | number;
}

const TaskNavigator = ({ leftPram, topParam, history }: TaskNavigatorProps) => {
  const { account } = useContext(AccountContext);

  const navigateToGenesisNFTPage = () => {
    history.push("/genesisNFT");
  };

  const navigateToTestPage = () => {
    history.push("/dna");
  };

  return (
    <Box
      position="sticky"
      left={leftPram}
      top={topParam}
      height="50vh"
      p={4}
      display={{ base: 'none', md: 'block' }}
    >
      <Flex align="center">
        <Image src={Rectangle_png} width={8} height={8} />
        <Text color="black" fontWeight={300} m={2} pr={2}>
          {account?.toString().slice(0, 6)}...
          {account?.toString().slice(-4)}
        </Text>
        <Link
          href={`https://sepolia.arbiscan.io/address/${account}`}
          target="_target"
        >
          <ExternalLinkIcon color="black" />
        </Link>
      </Flex>
      <Flex direction={"column"} gap={1} fontSize="lg" lineHeight={2}>
        <Flex
          border={
            history.location.pathname === "/dna" ? "1px solid black" : undefined
          }
          p={1}
          align="center"
          cursor={"pointer"}
          onClick={navigateToTestPage}
        >
          <Text color="black" as="p" pl={1} mr={2} >
            ME
          </Text>
          {history.location.pathname === "/dna" && (
            <Image src={Fire_png} width={6} height={6} />
          )}
        </Flex>
        <Flex
          border={
            history.location.pathname === "/genesisNFT"
              ? "1px solid black"
              : undefined
          }
          align="center"
          cursor={"pointer"}
          padding={2}
          onClick={navigateToGenesisNFTPage}
        >
          <Text color="black" as="p" mr={2} >
            Genesis NFT
          </Text>
          {history.location.pathname === "/genesisNFT" && (
            <Image src={Fire_png} width={6} height={6} />
          )}
        </Flex>
        <Accordion allowToggle>
          <AccordionItem border="none">
            {({ isExpanded }) => (
              <>
                <AccordionButton padding={2}>
                  <Flex align="center" cursor={"pointer"}>
                    <Text color="black" fontSize={18} mr={2}>
                      Tasks
                    </Text>
                    {isExpanded && (
                      <Image src={Fire_png} width={6} height={6} />
                    )}
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <RouterLink to="/tasks/1">
                    <Text color="black" as="p" ml={4} cursor="pointer">
                      ↪Tasks 1
                    </Text>
                  </RouterLink>
                  <RouterLink to="/tasks/2">
                    <Text color="black" as="p" ml={4} cursor="pointer">
                      ↪Tasks 2
                    </Text>
                  </RouterLink>
                  <RouterLink to="/tasks/3">
                    <Text color="black" as="p" ml={4} cursor="pointer">
                      ↪Tasks 3
                    </Text>
                  </RouterLink>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>
      </Flex>
    </Box>
  );
};

export default withRouter(TaskNavigator);
