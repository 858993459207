// src/pages/GenesisNFTPage.tsx
import {
  Box,
  Flex,
  Text,
  Grid,
  Link,
  VStack,
} from "@chakra-ui/react";
import { RouteComponentProps } from "react-router-dom";
import TaskNavigator from "@/components/TaskNavigator";
import genesisNFT from "@/pages/maketplaceConfig.json";

import GenesisTicket from "@/pages/GenesisTicket";
import RankingPanel from "@/pages/RankingPanel";
import useNFTData from "@/hooks/useNFTData";
import { Twin3Genesis_CONTRACT_ADDRESS } from "@/utils/constants";
import Twin3Genesis_ABI from "@/Twin3Genesis _abi.json";
import { useReadContracts } from "wagmi";
import { useEffect } from 'react';
import { useAppDispatch } from '@/state/hooks';
import { setRemainingSupply } from "@/state/genesisNFT/genesisNFTSlice";


const GenesisNFTPage = ({ history }: { history: RouteComponentProps["history"] }) => {
  const { displayValues } = useNFTData();
  const dispatch = useAppDispatch();

  let nft_maxSupply = [];
  let nft_totalSupply = [];

  const SupplyContract = {
    address: Twin3Genesis_CONTRACT_ADDRESS,
    abi: Twin3Genesis_ABI,
  } as const;

  const { data: totalSupply } = useReadContracts({
    contracts: [
      {
        ...SupplyContract,
        functionName: "totalSupply",
        args: [0],
      },
      {
        ...SupplyContract,
        functionName: "totalSupply",
        args: [1],
      },
      {
        ...SupplyContract,
        functionName: "totalSupply",
        args: [2],
      },
      {
        ...SupplyContract,
        functionName: "totalSupply",
        args: [3],
      },
      {
        ...SupplyContract,
        functionName: "totalSupply",
        args: [4],
      },
      {
        ...SupplyContract,
        functionName: "totalSupply",
        args: [5],
      },
    ],
  });

  const { data: maxSupply } = useReadContracts({
    contracts: [
      {
        ...SupplyContract,
        functionName: "maxSupply",
        args: [0],
      },
      {
        ...SupplyContract,
        functionName: "maxSupply",
        args: [1],
      },
      {
        ...SupplyContract,
        functionName: "maxSupply",
        args: [2],
      },
      {
        ...SupplyContract,
        functionName: "maxSupply",
        args: [3],
      },
      {
        ...SupplyContract,
        functionName: "maxSupply",
        args: [4],
      },
      {
        ...SupplyContract,
        functionName: "maxSupply",
        args: [5],
      },
    ],
  });

  nft_totalSupply =
    totalSupply?.map((tier) =>
      parseInt((tier.result as number).toString().replace("n", ""))
    ) || [];

  nft_maxSupply =
    maxSupply?.map((tier) =>
      parseInt((tier.result as number).toString().replace("n", ""))
    ) || [];

  useEffect(() => {
    const leftNFT: number[] = [];
    nft_totalSupply.forEach((supply, index) => {
      leftNFT.push(nft_maxSupply[index] - supply);
    })
    dispatch(setRemainingSupply(leftNFT));
    console.log('leftNFT', leftNFT);
  }, [nft_totalSupply, nft_maxSupply, dispatch]);

  const nftTiers = genesisNFT;

  return (
    <Flex py={20} justify={'center'} gap={'80px'}>
      {/* left sidebar */}
      <TaskNavigator topParam={96} />
      {/* genesisNFTPage */}
      <Flex
        flexDirection="column"
        mb={20}
        maxW={"750px"}
        p={{ base: 2, md: 0 }}
      >
        <Flex gap={16} direction={{ base: "column", lg: "row" }}>
          <Box flex={3} maxW={1440}>
            <Text color="black" fontSize="4xl" fontWeight="bold" my={2}>Genesis NFT</Text>
            <Text color="black" mb={2}>
              Become a supporter with community influence and profit-sharing rights, limited to 6,666 copies.
              {" "}
              <Link
                href="https://whitepaper.0xer.org"
                target="_blank"
                textDecoration="underline"
              >
                more
              </Link>
            </Text>
            <RankingPanel displayValues={displayValues} />
            <Grid
              templateColumns={{
                base: "repeat(1, 1fr)",
                md: "repeat(2, 1fr)",
                lg: "repeat(3, 1fr)",
              }}
              gap={6}
              justifyItems={"center"}
            >
              {nftTiers.map((tier) => (
                <VStack>
                  <GenesisTicket
                    id={tier.id}
                    name={tier.name}
                    src={tier.src}
                    points={tier.points}
                    left={
                      nft_maxSupply[tier.id - 1] - nft_totalSupply[tier.id - 1]
                        ? nft_maxSupply[tier.id - 1] - nft_totalSupply[tier.id - 1]
                        : 0
                    }
                  />
                </VStack>
              ))}
            </Grid>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default GenesisNFTPage;