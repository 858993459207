import { useState, useCallback, useEffect, useContext } from "react";
import { Text, Button, Image } from "@chakra-ui/react";
import { decrementSupplyByTier } from "@/state/genesisNFT/genesisNFTSlice";
import { useAppDispatch, useAppSelector } from '../state/hooks';
import AccountContext from "@/context/account";

import Image_1 from "@/assets/images/gif/1.gif";
import Image_2 from "@/assets/images/gif/2.gif";
import Image_3 from "@/assets/images/gif/3.gif";
import Image_4 from "@/assets/images/gif/4.gif";
import Image_5 from "@/assets/images/gif/5.gif";
import Image_6 from "@/assets/images/gif/6.gif";

import { getSignature } from "@/api";
import useExchangeGenesis from "@/libs/useExchangeGenesis";
import useReadScore from '@/libs/useReadScore';

interface ticketProps {
  id: number;
  name: string;
  src: string;
  points: number;
  left: number;
}

const GenesisTicket = ({ id, name, src, points, left }: ticketProps) => {
  const dispatch = useAppDispatch();
  const genesisNFTData = useAppSelector((state) => state.genesisNFT);
  const { account } = useContext(AccountContext);
  const { refetch } = useReadScore(account || "");

  const imageMap = {
    "1.gif": Image_1,
    "2.gif": Image_2,
    "3.gif": Image_3,
    "4.gif": Image_4,
    "5.gif": Image_5,
    "6.gif": Image_6,
  };

  const [canSubmit, setCanSubmit] = useState(true);

  const { submit, isConfirmed } = useExchangeGenesis(1, id);

  const handleSubmit = useCallback(async () => {
    if (!canSubmit) {
      return;
    }
    setCanSubmit(true);
    const response = await getSignature();
    await submit(response.signature);
  }, [canSubmit, submit]);

  useEffect(() => {
    if (isConfirmed) {
      dispatch(decrementSupplyByTier({ tier: id, amount: 1 }));
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmed]);

  return (
    <>
      <Text color="black" fontSize="xl" style={{ fontWeight: "bold" }}>
        Tier {id}
      </Text>
      <Text color="black" fontSize="xl" mb={3} style={{ fontWeight: "bold" }}>
        {name}
      </Text>
      <Image
        src={imageMap[src as keyof typeof imageMap]}
        width={216}
        height={216}
      />
      <Button
        sx={{ border: "1px solid" }}
        color="black"
        colorScheme="white"
        m={4}
        style={{ fontWeight: "normal" }}
        onClick={handleSubmit}
      >
        Redeem
      </Button>

      <Text color="gray" style={{ fontSize: "small" }}>
        {genesisNFTData.remainingSupply[id - 1]} Left
      </Text>
      <Text color="gray" style={{ fontSize: "small" }}>
        {points} Points / 1pc.
      </Text>
    </>
  );
};

export default GenesisTicket;
